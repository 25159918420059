import Profile1 from '../../assets/profiles/profile1.svg';
import Profile2 from '../../assets/profiles/profile2.svg';
import Profile3 from '../../assets/profiles/profile3.svg';
import Profile4 from '../../assets/profiles/profile4.svg';
import Profile5 from '../../assets/profiles/profile5.svg';

export const getUniqueCategories = (posts) => {
  const categorySet = new Set();
  posts.forEach(({ categories }) => categories.forEach((category) => categorySet.add(category)));
  return [...categorySet].sort((a, b) => {
    if (a === 'featured') return -1;
    if (b === 'featured') return 1;
    return 0;
  });
};

String.prototype.hashCode = function () {
  var hash = 0;
  for (var i = 0; i < this.length; i++) {
    var character = this.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const getProfilePicture = (name) => {
  const list = [Profile1, Profile2, Profile3, Profile4, Profile5];
  return list[Math.abs(name.hashCode()) % 5];
};
